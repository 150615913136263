// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import queryString from 'query-string';

// ** API Imports
import axios from '@src/service/axios';

// ** Toast Import
import { toast } from 'react-toastify';

// get all Leave Request
export const getLeaveRequest = createAsyncThunk('getAllLeaveData/getLeaveRequest', async (data) => {
    try {
        let response = await axios.post('/LeaveRequest/get-request', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

// get my Leave Request
export const getMyLeaveRequest = createAsyncThunk('getAllLeaveData/getMyLeaveRequest', async (data) => {
    try {
        let response = await axios.post('/LeaveRequest/get-my-request?', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

// create all Leave Request
export const createLeaveRequest = createAsyncThunk('getAllLeaveData/createLeaveRequest', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/LeaveRequest/request', data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        toast.success(response?.data?.message || 'Leave added succesfully.');
        // await dispatch(getLeaveRequest());
        return Promise.resolve(response);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

// create my Leave Request
export const createMyLeaveRequest = createAsyncThunk('getAllLeaveData/createMyLeaveRequest', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/LeaveRequest/my-request', data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        toast.success(response?.data?.message || 'Leave added succesfully.');
        // await dispatch(getLeaveRequest());
        return Promise.resolve(response);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

// update all Leave Request
export const updateLeaveRequest = createAsyncThunk('getAllAttendanceCorrectionData/updateLeaveRequest', async (data) => {
    try {
        let response = await axios.put('/LeaveRequest/request/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        toast.success(response?.data?.message || 'Leave updated succesfully.');
        return Promise.resolve(response);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

// update my Leave Request
export const updateMyLeaveRequest = createAsyncThunk('getAllAttendanceCorrectionData/updateMyLeaveRequest', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/LeaveRequest/my-request/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        toast.success(response?.data?.message || 'Leave updated succesfully.');
        return Promise.resolve(response);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

// delete any Leave Request
export const deleteLeaveRequest = createAsyncThunk('getAllAttendanceCorrectionData/deleteLeaveRequest', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/LeaveRequest/request/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Leave deleted successfully.');
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

// delete my Leave Request
export const deleteMyLeaveRequest = createAsyncThunk(
    'getAllAttendanceCorrectionData/deleteMyLeaveRequest',
    async (deleteId, { dispatch }) => {
        try {
            let response = await axios.delete('/LeaveRequest/my-request/' + deleteId, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            toast.success(response?.data?.message || 'Leave deleted successfully.');
            return Promise.resolve(response);
        } catch (err) {
            toast.error(err?.response?.data?.message || 'Something went wrong!');
            console.log('err', err);
            return Promise.reject(err);
        }
    }
);

export const deleteLeaveHistory = createAsyncThunk('getAllAttendanceCorrectionData/deleteLeaveHistory', async (data) => {
    try {
        let response = await axios.put('/EmployeeLeave/delete-leave-history/' + data?.deleteId, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Leave History deleted successfully.');
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const getLeaveRequestRemarks = createAsyncThunk('getAllLeaveData/getLeaveRequestRemarks', async (id) => {
    try {
        let response = await axios.get('/LeaveRequest/remarks-history/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateLeaveChangeRemarks = createAsyncThunk('getAllLeaveData/updateLeaveChangeRemarks', async (data) => {
    try {
        let response = await axios.put('/LeaveRequest/request-status/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Remarks updated succesfully.');

        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateLeaveBulkChangeRemarks = createAsyncThunk('getAllLeaveData/updateLeaveBulkChangeRemarks', async (data) => {
    try {
        let response = await axios.put('/LeaveRequest/bulk-request-status', data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Remarks updated succesfully.');

        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getLeavecategoryOptions = createAsyncThunk('getAllLeaveData/getLeavecategoryOptions', async (data) => {
    try {
        const query = queryString.stringify(data?.data);
        let response = await axios.get('/EmployeeLeave?option=true&' + query, {
            headers: {
                'Content-Type': 'application/json',
                IsAdmin: data?.isAdmin ? data?.isAdmin : false
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getLeaveRuleByEmployee = createAsyncThunk('getAllLeaveData/getLeaveRuleByEmployee', async (data) => {
    try {
        const query = queryString.stringify(data);
        let response = await axios.get('/EmployeeLeave/leave-rule-by-employee?' + query, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data.leave);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getLeaveHistoryByEmployee = createAsyncThunk('getAllLeaveData/getLeaveHistoryByEmployee', async (data) => {
    try {
        const query = queryString.stringify(data);
        let response = await axios.get('/EmployeeLeave/leave-history-by-rule?' + query, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getLeaveRuleByMyProfile = createAsyncThunk('getAllLeaveData/getLeaveRuleByMyProfile', async (data) => {
    try {
        const query = queryString.stringify(data);
        let response = await axios.get('/EmployeeLeave/my-profile-leave-history-by-rule?' + query, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getLeaveBalance = createAsyncThunk('getAllLeaveData/getLeaveBalance', async (data) => {
    try {
        const query = queryString.stringify(data);
        let response = await axios.get('/EmployeeLeave/get-leaves?' + query, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.leave);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const leaveSlice = createSlice({
    name: 'getAllAttendanceCorrectionData',
    initialState: {
        leave: [],
        myLeave: [],
        leavecategoryOptions: [],
        leaveBalance: 0,
        leaveRequestRemarks: [],
        leaveHistoryByEmployee: [],
        leaveRuleByEmployee: 0,
        leaveRuleByMyProfile: []
    },
    reducers: {
        removeLeave: (state) => {
            state.leave = [];
        },
        removeMyLeave: (state) => {
            state.myLeave = [];
        },
        removeLeaveCategory: (state) => {
            state.leavecategoryOptions = [];
        },
        removeLeaveBalance: (state) => {
            state.leaveBalance = 0;
        },
        removeleaveRuleByEmployeeLeave: (state) => {
            state.leaveRuleByEmployee = 0;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getLeaveRequest.fulfilled, (state, action) => {
            state.leave = action?.payload;
            return;
        });
        builder.addCase(getMyLeaveRequest.fulfilled, (state, action) => {
            state.myLeave = action?.payload;
            return;
        });
        builder.addCase(getLeavecategoryOptions.fulfilled, (state, action) => {
            state.leavecategoryOptions = action?.payload;
            return;
        });
        builder.addCase(getLeaveBalance.fulfilled, (state, action) => {
            state.leaveBalance = action?.payload;
            return;
        });
        builder.addCase(getLeaveRequestRemarks.fulfilled, (state, action) => {
            state.leaveRequestRemarks = action?.payload;
            return;
        });
        builder.addCase(getLeaveRuleByEmployee.fulfilled, (state, action) => {
            state.leaveRuleByEmployee = action?.payload;
            return;
        });
        builder.addCase(getLeaveRuleByMyProfile.fulfilled, (state, action) => {
            state.leaveRuleByMyProfile = action?.payload;
            return;
        });
        builder.addCase(getLeaveHistoryByEmployee.fulfilled, (state, action) => {
            state.leaveHistoryByEmployee = action?.payload;
            return;
        });
    }
});
export const { removeLeave, removeMyLeave, removeLeaveCategory, removeLeaveBalance, removeleaveRuleByEmployeeLeave } = leaveSlice.actions;
export default leaveSlice.reducer;
