import holidaySlice from '../views/Master/HolidayMaster/store/index';
import locationSlice from '../views/Location/store';
import allowanceSlice from '../../src/views/Master/Allowance/store/index';
import deductionSlice from '../../src/views/Master/Deduction/store/index';
import leaveCategorySlice from '../views/Master/LeaveCategoryMaster/store/index';
import shiftSlice from '../../src/views/Master/Shift/store/index';
import weekOffSlice from '../../src/views/Master/WeekOf/store/index';
import gradeSlice from '../../src/views/EmployeeMaster/Grade/store/index';
import designationSlice from '../../src/views/EmployeeMaster/Designation/store/index';
import branchSlice from '../views/CompanyMaster/Branch/store/index';
import departmentSlice from '../../src/views/EmployeeMaster/Department/store/index';
import employeetypeSlice from '../../src/views/EmployeeMaster/Type/store/index';
import myProfileSlice from '../views/MyProfile/store';
import userSlice from '../../src/views/Setup/User/store/index';
import roleSlice from '../../src/views/Setup/RoleRights/Role/store/index';
import employeeSlice from '../views/EmployeeMaster/store';
import deviceSlice from '../views/Biometric/Device/store/index';
import kycDocumentSlice from '../views/CompanyMaster/KycDocument/store/index';
import attendanceSlice from '../views/Attendance/store';
import auditLogSlice from '../views/AuditLog/Tabs/AuditLog/store/index';
import loginLogSlice from '../views/AuditLog/Tabs/LoginLog/store/index';
import shiftChangeSlice from '../../src/views/Hr/Requests/Tabs/ShiftChange/store/index';
import attendanceCorrectionSlice from '../views/Hr/Requests/Tabs/Correction/store/index';
import regularizationSlice from '../views/Hr/Requests/Tabs/Regularization/store/index';
import leaveSlice from '../views/Hr/Requests/Tabs/Leave/store/index';
import requestRemarksSlice from '../views/Hr/Requests/Tabs/store';
import LeaveRuleSlice from '../views/CompanyMaster/LeaveRules/store/index';
import OverTimeSlice from '../views/Hr/Requests/Tabs/OverTime/store/index';
import moduleSlice from '../views/ModuleMaster/store/index';
import incomeCategorySlice from '../views/Master/IncomeCategory/store';
import consolidationSlice from '../../src/views/Salary/Consolidation/store/index';
import salarySlipSlice from '../views/Salary/SalarySlip/store/index';
import dayWiseSalarySlice from '../views/Salary/DayWiseSalary/store/index';
import dashboard from '../views/Dashboard/NewStore';
const crudApis = {
    holidaySlice,
    locationSlice,
    allowanceSlice,
    deductionSlice,
    leaveCategorySlice,
    shiftSlice,
    gradeSlice,
    designationSlice,
    branchSlice,
    departmentSlice,
    employeetypeSlice,
    myProfileSlice,
    userSlice,
    roleSlice,
    employeeSlice,
    deviceSlice,
    kycDocumentSlice,
    attendanceSlice,
    shiftChangeSlice,
    attendanceCorrectionSlice,
    regularizationSlice,
    leaveSlice,
    requestRemarksSlice,
    LeaveRuleSlice,
    OverTimeSlice,
    moduleSlice,
    auditLogSlice,
    incomeCategorySlice,
    consolidationSlice,
    salarySlipSlice,
    loginLogSlice,
    dashboard,
    dayWiseSalarySlice,
    weekOffSlice
};

export default crudApis;
