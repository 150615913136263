// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ** API Imports
import axios from '@src/service/axios';

import { toast } from 'react-toastify';

export const getRole = createAsyncThunk('roleSliceData/getRole', async () => {
    try {
        let response = await axios.get('/role', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
export const deleteRole = createAsyncThunk('roleSliceData/deleteRole', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/role/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Role Deleted Succesfully');
        await dispatch(getRole());
        return Promise.resolve(response);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createRole = createAsyncThunk('roleSliceData/createRole', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/role', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Role Added Succesfully');
        await dispatch(getRole());
        return Promise.resolve(response);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateRole = createAsyncThunk('roleSliceData/updateRole', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/role/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Role Updated Succesfully');
        await dispatch(getRole());
        return Promise.resolve(response);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateRoleStatus = createAsyncThunk('roleSliceData/updateRoleStatus', async (id, { dispatch }) => {
    try {
        let response = await axios.put('/role/status/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Status Updated Succesfully');
        dispatch(getRole());
        return Promise.resolve(response);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getRoleId = createAsyncThunk('roleSliceData/getRolebyId', async (id, includes = 0) => {
    try {
        let response = await axios.get('/role/' + id, {
            headers: {
                'Content-Type': 'application/json',
                includes: includes
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const roleSlice = createSlice({
    name: 'roleSliceData',
    initialState: {
        role: []
    },
    extraReducers: (builder) => {
        builder.addCase(getRole.fulfilled, (state, action) => {
            state.role = action?.payload;
            return;
        });
    }
});

export default roleSlice.reducer;
