// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import queryString from 'query-string';

// ** API Imports
import axios from '@src/service/axios';

//** Toast Import
import { toast } from 'react-toastify';

//** Employee By Id Api

// export const getEmployeeById = createAsyncThunk('getProfileData/getEmployeeById', async (id, { dispatch }) => {
//     try {
//         let response = await axios.get('/Employee/' + id, {
//             headers: {
//                 'Content-Type': 'application/json'
//             }
//         });

//         dispatch(getEmployeeRemarks(id));
//         return Promise.resolve(response?.data);
//     } catch (err) {
//         console.log('err', err);
//         toast.error(err?.response?.data?.message || 'Something went wrong!');
//         return Promise.reject(err);
//     }
// });

export const getPersonalandContactdetailsById = createAsyncThunk(
    'getProfileData/getPersonalandContactdetailsById',
    async (tempEmpId, { dispatch }) => {
        try {
            let response = await axios.get(`/Employee/personal-and-contact?employeeId=${tempEmpId}`, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            return Promise.resolve(response?.data);
        } catch (err) {
            console.log('err', err);
            toast.error(err?.response?.data?.message || 'Something went wrong!');
            return Promise.reject(err);
        }
    }
);
export const getDocumentsById = createAsyncThunk('getProfileData/getDocumentsById', async (tempEmpId, { dispatch }) => {
    try {
        let response = await axios.get(`/Employee/documents?employeeId=${tempEmpId}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
export const getBankDetailsById = createAsyncThunk('getProfileData/getBankDetailsById', async (tempEmpId, { dispatch }) => {
    try {
        let response = await axios.get(`/Employee/bank-details/?employeeId=${tempEmpId}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
export const getOfficeDetailsById = createAsyncThunk('getProfileData/getOfficeDetailsById', async (tempEmpId, { dispatch }) => {
    try {
        let response = await axios.get(`/Employee/office-details/?employeeId=${tempEmpId}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

//** Update Personal Detail Api
export const updateEmployee = createAsyncThunk('getProfileData/updateEmployee', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/MyProfile/personalDetails', data?.data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        toast.success(response?.data?.message || 'Employee updated succesfully.');
        await dispatch(getPersonalandContactdetailsById(data?.id));
        return Promise.resolve(response);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

//** Update Contact Details Api
export const updateContactDetails = createAsyncThunk('getProfileData/updateContactDetails', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/MyProfile/contactDetails', data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Employee updated succesfully.');
        await dispatch(getPersonalandContactdetailsById(data?.id));
        return Promise.resolve(response);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

//** Update Bank Details Api
export const updateBankDetails = createAsyncThunk('getProfileData/updateBankDetails', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/MyProfile/bankDetails', data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Employee updated succesfully.');
        await dispatch(getBankDetailsById(data?.id));
        return Promise.resolve(response);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

//** Update Official Details Api
export const updateOfficialDetails = createAsyncThunk('getProfileData/updateOfficialDetails', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/MyProfile/offcialDetails', data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Employee updated succesfully.');
        await dispatch(getOfficeDetailsById(data?.id));
        return Promise.resolve(response);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const removeProfilePictures = createAsyncThunk('getProfileData/removeProfilePicture', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/Employee/remove-profile/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Employee updated succesfully.');
        await dispatch(getPersonalandContactdetailsById(data?.id));
        return Promise.resolve(response);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getEmployeeSalary = createAsyncThunk('getProfileData/getEmployeeSalary', async () => {
    try {
        let response = await axios.get('/IncomeCategory?option=true', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getEmployeeCurrentSalaryById = createAsyncThunk('getProfileData/getEmployeeCurrentSalaryById', async (data) => {
    try {
        let response = await axios.get('/Salary/current-salary/' + data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getEmployeeSalaryById = createAsyncThunk('getAllEmployeeData/getEmployeeSalaryById', async (data) => {
    try {
        let response = await axios.get('/Salary/' + data?.employeeId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
//** employee education

export const getEducationDetail = createAsyncThunk('getProfileData/getEducationDetail', async (tempEmpId, { dispatch }) => {
    try {
        let response = await axios.get(`/EmployeeEducation/get-employee-education?employeeId=${tempEmpId}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        // await dispatch(getEmployeeById(tempEmpId));
        // await dispatch(getEmployeeRemarks(tempEmpId));
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createEducationCourseDetails = createAsyncThunk('getProfileData/createEducationCourseDetails', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/MyProfile/add-employee-education-course', data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Employee course  Add Successfully.');
        await dispatch(getEducationDetail(data?.empId));
        return Promise.resolve(response);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

//update course
export const updateEducationCourseDetails = createAsyncThunk('getProfileData/updateEducationCourseDetails', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/MyProfile/edit-employee-education-course/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || ' Employee Course Updated Succesfully');
        await dispatch(getEducationDetail(data?.empId));
        return Promise.resolve(response);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createEducationWorkDetails = createAsyncThunk('getProfileData/createEducationWorkDetails', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/MyProfile/add-employee-education-work', data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Employee work Add Successfully.');
        await dispatch(getEducationDetail(data?.empId));
        return Promise.resolve(response);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateEducationWorkDetails = createAsyncThunk('getProfileData/updateEducationWorkDetails', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/MyProfile/edit-employee-education-work/' + data.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Employee Updated Succesfully');
        await dispatch(getEducationDetail(data?.empId));
        return Promise.resolve(response);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const deleteEmployeeEducation = createAsyncThunk('getProfileData/deleteEmployeeEducation', async (data, { dispatch }) => {
    try {
        let response = await axios.delete('/MyProfile/delete-employee-education-work/' + data.deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Employee Delete Successfully.');
        await dispatch(getEducationDetail(data.empId));
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

//** Kyc Document Api

export const getKycDocumentName = createAsyncThunk('getProfileData/getKycDocumentName', async () => {
    try {
        let response = await axios.get('/KycDocMaster', {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
export const getEmployeeKycDoc = createAsyncThunk('getProfileData/getEmployeeKycDoc', async () => {
    try {
        let response = await axios.get('/EmployeeKycDoc', {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createEmployeeKycDoc = createAsyncThunk('getProfileData/createEmployeeKycDoc', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/EmployeeKycDoc', data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        toast.success(response?.data?.message || 'Document Created succesfully.');
        return Promise.resolve(response);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateEmployeeKycDoc = createAsyncThunk('getProfileData/updateEmployeeKycDoc', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/EmployeeKycDoc/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        toast.success(response?.data?.message || 'Document updated succesfully.');
        await dispatch(getPersonalandContactdetailsById(data?.empId));
        return Promise.resolve(response);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateEmpKycDocKycStatus = createAsyncThunk('getProfileData/updateEmpKycDocKycStatus', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/EmployeeKycDoc/kycstatus/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Status updated succesfully.');
        await dispatch(getEmployeeRemarks(data?.empId));
        return Promise.resolve(response);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

//** Employee Remark Api

export const getEmployeeRemarks = createAsyncThunk('getProfileData/getEmployeeRemarks', async (id) => {
    try {
        let response = await axios.get('/Remark/get-remarks/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

//Salary Slip for employee tab
export const getSalarySlip = createAsyncThunk('getAllEmployeeData/getSalarySlip', async () => {
    try {
        let response = await axios.get('/Salary/my-paid-salary-slip', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
export const getLeaveRule = createAsyncThunk('getProfileData/getLeaveRule', async (tempEmpId) => {
    try {
        // const query = queryString.stringify(data);

        let response = await axios.get(`/EmployeeLeave?employeeId=${tempEmpId}`, {
            headers: {
                'Content-Type': 'application/json',
                IsAdmin: true
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
export const getEmployeeStatus = createAsyncThunk('getEmployeeRemarksData/getEmployeeStatus', async (tempEmpId) => {
    try {
        let response = await axios.get(`/Employee/Status?employeeId=${tempEmpId}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
export const getMyProfilePreviewSalarySlip = createAsyncThunk('getProfileData/getMyProfilePreviewSalarySlip', async (data) => {
    const query = queryString.stringify(data);
    try {
        let response = await axios.get('/Salary/my-profile-preview-salary-slip?' + query, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
export const myProfileSlice = createSlice({
    name: 'getProfileData',
    initialState: {
        employeeDetail: {},
        employeeStatus: {},
        employeePersonalandContactdetails: {},
        kycDocumentMasterDetail: [],
        employeeKycDocDetail: [],
        employeeDocuments: [],
        employeeBankDetails: [],
        employeeOfficeDetails: [],
        employeeEducation: [],
        leaveRule: [],
        employeeRemarks: [],
        salary: [],
        employeesalary: [],
        currentEmployeesalary: [],
        salarySlip: [],
        myProfilePreviewSalarySlip: []
    },

    reducers: {
        removePreviewSalarySlip: (state) => {
            state.myProfilePreviewSalarySlip = [];
        }
    },

    extraReducers: (builder) => {
        // builder.addCase(getEmployeeById.fulfilled, (state, action) => {
        //     state.employeeDetail = action?.payload;
        //     return;
        // });
        builder.addCase(getEmployeeStatus.fulfilled, (state, action) => {
            state.employeeStatus = action?.payload;
            return;
        });
        builder.addCase(getPersonalandContactdetailsById.fulfilled, (state, action) => {
            state.employeePersonalandContactdetails = action?.payload;
            return;
        });
        builder.addCase(getDocumentsById.fulfilled, (state, action) => {
            state.employeeDocuments = action?.payload;
            return;
        });
        builder.addCase(getBankDetailsById.fulfilled, (state, action) => {
            state.employeeBankDetails = action?.payload;
            return;
        });
        builder.addCase(getOfficeDetailsById.fulfilled, (state, action) => {
            state.employeeOfficeDetails = action?.payload;
            return;
        });

        builder.addCase(getKycDocumentName.fulfilled, (state, action) => {
            state.kycDocumentMasterDetail = action?.payload;
            return;
        });
        builder.addCase(getEmployeeKycDoc.fulfilled, (state, action) => {
            state.employeeKycDocDetail = action?.payload;
            return;
        });
        builder.addCase(getEducationDetail.fulfilled, (state, action) => {
            state.employeeEducation = action?.payload;
            return;
        });
        builder.addCase(getEmployeeRemarks.fulfilled, (state, action) => {
            state.employeeRemarks = action?.payload;
            return;
        });
        builder.addCase(getLeaveRule.fulfilled, (state, action) => {
            state.leaveRule = action?.payload;
            return;
        });
        builder.addCase(getEmployeeSalary.fulfilled, (state, action) => {
            state.salary = action?.payload;
            return;
        });
        builder.addCase(getEmployeeCurrentSalaryById.fulfilled, (state, action) => {
            state.currentEmployeesalary = action?.payload;
            return;
        });
        builder.addCase(getEmployeeSalaryById.fulfilled, (state, action) => {
            state.employeesalary = action?.payload;
            return;
        });
        builder.addCase(getSalarySlip.fulfilled, (state, action) => {
            state.salarySlip = action?.payload;
            return;
        });
        builder.addCase(getMyProfilePreviewSalarySlip.fulfilled, (state, action) => {
            state.myProfilePreviewSalarySlip = action?.payload;
            return;
        });
    }
});

export const { removePreviewSalarySlip } = myProfileSlice.actions;
export default myProfileSlice.reducer;
