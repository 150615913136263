// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import queryString from 'query-string';

// ** API Imports
import axios from '@src/service/axios';

import { toast } from 'react-toastify';

export const getDayWiseSalary = createAsyncThunk('getAllDayWiseSalaryData/getDayWiseSalary', async (data) => {
    const query = queryString.stringify(data);
    try {
        let response = await axios.get('/Salary/daywise-salary?' + query, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(JSON.parse(response?.data));
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const dayWiseSalarySlice = createSlice({
    name: 'getAllDayWiseSalaryData',

    initialState: {
        dayWiseSalary: []
    },
    extraReducers: (builder) => {
        builder.addCase(getDayWiseSalary.fulfilled, (state, action) => {
            state.dayWiseSalary = action?.payload;
            return;
        });
    }
});

export default dayWiseSalarySlice.reducer;
