//** module Api
// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ** API Imports
import axios from '@src/service/axios';

import { toast } from 'react-toastify';
export const getModules = createAsyncThunk('moduleSliceData/getModules', async (includes = 0, { dispatch }) => {
    try {
        let response = await axios.get('/module', {
            headers: {
                'Content-Type': 'application/json',
                includes: includes
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createModule = createAsyncThunk('moduleSliceData/createModule', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/module', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Module added succesfully.');
        await dispatch(getModules());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updateModule = createAsyncThunk('moduleSliceData/updateModule', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/module/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Module updated succesfully.');
        await dispatch(getModules());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updateModuleStatus = createAsyncThunk('moduleSliceData/updateModuleStatus', async (id, { dispatch }) => {
    try {
        let response = await axios.put('/module/status/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || ' Status updated successfully !');
        await dispatch(getModules());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const deleteModule = createAsyncThunk('moduleSliceData/deleteModule', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/module/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Module deleted successfully.');
        await dispatch(getModules());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});
export const moduleSlice = createSlice({
    name: 'moduleSliceData',
    initialState: {
        modules: []
    },
    extraReducers: (builder) => {
        builder.addCase(getModules.fulfilled, (state, action) => {
            state.modules = action?.payload;
            return;
        });
    }
});

export default moduleSlice.reducer;
