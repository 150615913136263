// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ** API Imports
import axios from '@src/service/axios';

// ** Toast Imports
import { toast } from 'react-toastify';

export const getWeekOff = createAsyncThunk('weekOffSliceData/getWeekOff', async () => {
    try {
        let response = await axios.get('/WeekOff', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createWeekOff = createAsyncThunk('weekOffSliceData/createWeekOff', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/WeekOff', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'WeekOff added succesfully.');
        await dispatch(getWeekOff());
        return Promise.resolve(response);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateWeekOff = createAsyncThunk('weekOffSliceData/updateWeekOff', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/WeekOff/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'WeekOff updated succesfully.');
        await dispatch(getWeekOff());
        return Promise.resolve(response);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const deleteWeekOff = createAsyncThunk('weekOffSliceData/deleteWeekOff', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/WeekOff/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'WeekOff deleted successfully.');
        await dispatch(getWeekOff());
        return Promise.resolve(response);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateWeekOffStatus = createAsyncThunk('weekOffSliceData/updateWeekOffStatus', async (id, { dispatch }) => {
    try {
        let response = await axios.put('/WeekOff/status/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Status Updated Succesfully');
        await dispatch(getWeekOff());
        return Promise.resolve(response);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const weekOffSlice = createSlice({
    name: 'weekOffSliceData',
    initialState: {
        weekOff: []
    },
    extraReducers: (builder) => {
        builder.addCase(getWeekOff.fulfilled, (state, action) => {
            state.weekOff = action?.payload;
            return;
        });
    }
});

export default weekOffSlice.reducer;
