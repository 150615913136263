// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ** API Imports
import axios from '@src/service/axios';

import { toast } from 'react-toastify';

export const getHoliday = createAsyncThunk('holidaySliceData/getHoliday', async () => {
    try {
        let response = await axios.get('/Holiday', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});
export const deleteHoliday = createAsyncThunk('holidaySliceData/deleteHoliday', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/Holiday/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Holiday deleted successfully.');
        await dispatch(getHoliday());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const createHoliday = createAsyncThunk('holidaySliceData/createHoliday', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/Holiday', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Holiday added succesfully.');
        await dispatch(getHoliday());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updateHoliday = createAsyncThunk('holidaySliceData/updateHoliday', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/Holiday/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Holiday updated succesfully.');
        await dispatch(getHoliday());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updateHolidayStatus = createAsyncThunk('holidaySliceData/updateHolidayStatus', async (id, { dispatch }) => {
    try {
        let response = await axios.put('/Holiday/status/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Status Updated Succesfully');
        await dispatch(getHoliday());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});
export const holidaySlice = createSlice({
    name: 'holidaySliceData',
    initialState: {
        holiday: []
    },
    extraReducers: (builder) => {
        builder.addCase(getHoliday.fulfilled, (state, action) => {
            state.holiday = action?.payload;
            return;
        });
    }
});

export default holidaySlice.reducer;
