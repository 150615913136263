var service = {};

if (process.env.NODE_ENV === 'production') {
    service.API_URL = 'https://wms-dev-api.teamfeeltech.com';
    // service.API_URL = 'https://hrms-react-client.vercel.app';
} else {
    service.API_URL = 'http://192.168.29.253:8585';
}

export default service;
